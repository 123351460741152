.workflow-detail {
	display: flex;

	&.row,
	.row {
		flex-direction: row;
	}

	.flex {
		display: flex;
	}

	.flex-one {
		flex: 1;
		padding: 0 1em;
	}

	.flex-four {
		flex: 4;
	}

	.flex-eight {
		flex: 8;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	.edfm_tab_container {
		width: 85%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;

		border: 1px solid #ccc;
		border-radius: 3px;
		-webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
		box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);

		.edfm_tab_item {
			width: 85%;
			margin: 0 auto;
		}

		.edfm_tab_bar {
			background-color: #1089ff;
			text-transform: uppercase;
			color: white;

			-webkit-box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.33);
			box-shadow: 0px 10px 15px 1px rgba(0, 0, 0, 0.33);

			border-radius: 3px;

			margin-bottom: 1em;

			ul {
				display: flex;
				flex-direction: row;
				margin: 0;
				height: 50px;

				li {
					flex-grow: 1;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;

					box-sizing: border-box;
					color: #bcd3f7;

					&.active {
						font-weight: bold;
						color: white;
						border-bottom: 2px solid white;
					}
				}
			}
		}

		.logtab_buttons {
			text-align: right;
			margin-bottom: 2.5em;
		}
	}
}
