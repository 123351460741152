@import 'scss/colours.scss';
@import 'scss/normalize.css';
@import 'scss/skeleton.scss';

// Styling imported by EDF material design themed compnonets

//colours

//shadow
$material-shadow1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$material-shadow2: 0 3px 7px 0 rgba(0, 0, 0, 0.24), 0 3px 12px 0 rgba(0, 0, 0, 0.18);

//microanimations
$transTime: 0.25s;

.edfmat_card {
	background-color: #fff;
	box-shadow: $material-shadow1;
	border-radius: 5px;
	padding: 15px;
}

// .edfmat_button{
//   display: inline-block;
//   box-shadow: $material-shadow1;
//   cursor: pointer;
//   margin-right: 10px;

//   &.edfmat_button--primary{
//       background-color: $primary-colour;
//       color: #fff;
//   }
// }

.edfmat_navbar {
	background-color: $primary-colour;
	color: #fff;

	min-height: 65px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	//&.edfmat_navbar--primary{
	//    background-color: $primary-colour;

	// .edfmat_button{
	//     color: #fff;
	//     box-shadow: none;
	// }

	.edfmat_button {
		// padding-right: 15px;
		// padding-left: 15px;
		// padding-top: 8px;
		// padding-bottom: 10px;
		color: #fff;
		// text-decoration: none;
		// display: inline-block;
		box-shadow: none;
		background-color: transparent;

		&.edfmat_button--active {
			background-color: rgba(255, 255, 255, 0.1);
		}

		&:not(:last-of-type) {
			margin-right: 0px; //as standard box not shown
		}

		&:hover {
			color: rgba(255, 255, 255, 0.75);
			transform: translateY(0);
			box-shadow: none;
		}
	}
	// }

	&.edfmat_navbar--fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
	}

	box-shadow: $material-shadow1;

	.TellerName {
		font-size: 1.9rem;
		padding: 10px 10px 10px 50px;
		min-width: 100px;
	}

	.menu {
		margin-right: auto;
	}

	.edf-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			margin: 10px 4px;
			width: 100px;
		}
		font-size: 1rem;

		padding: 10px 50px 10px 10px;
	}
}

.edfmat_tabbar {
	display: block;
	height: 53px;

	.edfmat_tabbar--container {
		display: flex;
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;

		.edfmat_button {
			flex: 1;
			margin-right: 0 !important;
			box-shadow: none !important;
			position: relative;

			&.edfmat_button--active {
				box-shadow: 0 2px 0 $primary-colour !important;
				color: $primary-colour;
			}

			&:hover {
				transform: translateY(0);
				box-shadow: none;

				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-color: $primary-colour;
					opacity: 0.2;
				}
			}
		}
	}
}

.edfmat_table_sticky--container {
	position: fixed;
	top: 65px;
	width: calc(100% - 60px); //for fullscreen an unchanging padding
	padding-left: 20px;
	overflow: hidden;
	z-index: 50;
	background-color: rgb(34, 34, 34);
	color: #fff;
	left: 20px;

	&.edfmat_table_sticky--container--none {
		display: none;
	}

	// &:after{
	//   content: "";
	//   position: fixed;
	//   display: block;
	//   background-color: rgb(34, 34, 34);
	//   width: 20px;
	//   height: 48px;
	//   z-index: 5000;
	//   top: 65px;
	//   right: 20px;
	// }

	.edfmat_table {
		height: 48px;
		//position: absolute;
		thead {
			position: absolute;
		}
	}
}

.edfmat_table {
	&.edfmat_table--slim {
		td {
			padding: 8px 10px;
		}
	}

	.edfmat_table_hidden {
		visibility: hidden;
	}
	.edfmat_table_none {
		display: none;
	}

	.edfmat_table_sticky {
		// position: fixed;
		// top: 65px;
		// width: calc( 100% - 80px);//for fullscreen an unchanging padding
		// padding-left: 20px;
		// overflow: hidden;
		// z-index: 50;
		// background-color: rgb(34, 34, 34);
		// color: #fff;
		// left: 20px;

		// &:after{
		//   content: "";
		//   position: fixed;
		//   display: block;
		//   background-color: rgb(34, 34, 34);
		//   width: 20px;
		//   height: 48px;
		//   z-index: 5000;
		//   top: 65px;
		//   right: 20px;
		// }

		tr {
			white-space: nowrap;
		}

		th {
			display: inline-block; //so width setting makes an impact
			padding-left: 0;
			// padding-right: 0;

			// &:first-child {
			// 	//padding-right: 20px;
			// }
		}
	}

	th.current {
		box-shadow: 0 -2px 0 #da3e00 inset !important;
		color: #da3e00;
	}

	tr:nth-child(even) {
		background-color: #f9f9f9;
	}

	th,
	td {
		padding: 12px 15px;
		text-align: left;
		border-bottom: 1px solid #e1e1e1;
	}
	// th:first-child,
	// td:first-child {
	//   padding-left: 0;
	// }
	// th:last-child,
	// td:last-child {
	//   padding-right: 0;
	// }

	&.edfmat_table--compressed {
		th,
		td {
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.edfmat_datatable {
	overflow-x: auto;

	.edfmat_datatable--empty {
		margin-top: 12px;
	}
	.edfmat_datatable--link tr {
		cursor: pointer;

		td:first-child {
			padding-left: 12px;
		}
	}
	.edfmat_datatable--link tr:hover {
		background-color: #e1e1e1;
	}

	.edfmat_datatable--loader {
		height: calc(100% - 60px);
		display: block;
		position: absolute;
		width: calc(100% - 20px);
		background-color: rgba(255, 255, 255, 0.8);
		top: 54px;
		z-index: 5;

		div {
			text-align: center;
		}
	}
}
.edfmat_datatable--pagination {
	min-height: 30px;
	padding-top: 15px;
}

.edfmat_chip {
	background-color: #e1e1e1;
	display: inline-block;
	padding: 5px;
	border-radius: 11px;
	position: relative;
	padding-right: 10px;
	padding-left: 10px;
	font-size: 1rem;
	box-shadow: $material-shadow1;
	min-height: 16px;
	vertical-align: top;
	margin-bottom: 10px;

	&.edfmat_chip--close {
		padding-right: 30px;
	}

	&:not(:last-of-type) {
		margin-right: 10px;
	}

	&.edfmat_chip--button {
		cursor: pointer;
	}

	.edfmat_chip_close-btn {
		border-radius: 100%;
		display: inline-block;
		width: 18px;
		height: 18px;
		color: #fff;
		top: 4px;
		position: absolute;
		text-align: center;
		line-height: 18px;
		right: 4px;
		background-color: $secondary-colour;
	}
}

// form{
.edfmat_input--container {
	margin-top: 0;
	margin-bottom: 15px;
	display: inline-block;
}
.edfmat_input--label {
	input {
		background-color: transparent;
	}

	position: relative;

	font-weight: 300;

	.edfmat_input,
	.edfmat_textarea,
	.edfmat_select {
		// height: 38px;
		// padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
		// background-color: #fff;
		// border: 1px solid #D1D1D1;
		// border-radius: 4px;
		// box-shadow: none;
		// box-sizing: border-box;

		padding-left: 0px !important;
		margin-top: 10px;
		font-weight: 300;
		outline: none;

		border-radius: 0 !important;
		border: none !important;
		box-shadow: 0 2px 0 0 rgb(172, 172, 172) !important;
		transition: box-shadow $transTime;

		&:active,
		&:focus {
			box-shadow: 0 2px 0 0 $primary-colour !important;
		}

		&::placeholder {
			color: rgba(255, 255, 255, 0);
			opacity: 0;
		}

		&:placeholder-shown + .input-label {
			position: absolute;
			top: -3px;
			left: 0;

			font-size: 1.5rem;
		}

		+ .input-label {
			position: absolute;
			top: -17px;
			left: 0;

			font-weight: 300;

			font-size: 1rem;

			transition: top $transTime, font-size $transTime;
		}

		&:focus + .input-label {
			position: absolute;
			left: 0;
			top: -17px;

			font-size: 1rem;
		}

		// .edfmat_textarea{
		//     border-radius: 0;
		//     border: none !important;
		//     box-shadow: 0 2px 0 0 $primary-colour;
		// }
	}
}

.edfmat_textarea--container {
	position: relative;

	.edfmat_input--label {
		margin-bottom: 15px;
		//display: inline-block;
	}

	label {
		position: absolute;
	}

	.edfmat_textarea {
		// border-radius: 0;
		// border: none !important;
		// box-shadow: 0 2px 0 0 $primary-colour;

		&:placeholder-shown + .input-label {
			position: absolute;
			top: 5px;
			left: 0;

			font-size: 1.5rem;
		}

		+ .input-label {
			position: absolute;
			top: -3px;
			left: 0;

			font-weight: 300;

			font-size: 1rem;

			transition: top $transTime, font-size $transTime;
		}

		&:focus + .input-label {
			position: absolute;
			left: 0;
			top: -3px;

			font-size: 1rem;
		}
	}
}

.edfmat_select-holder {
	margin-bottom: 15px;

	&.edfmat_select-holder--inline {
		display: flex;

		float: right; //temp - works for datatable *with* search, but needs to be universal / without search

		.edfmat_select-label {
			line-height: 38px;
		}
	}

	.edfmat_select {
		select {
			height: 38px;
			padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
			background-color: #fff;
			border: 1px solid #e1e1e1;
			border-radius: 4px;
			box-shadow: none;
			box-sizing: border-box;
		}
	}
	select:focus {
		border: 1px solid $primary-colour;
		outline: 0;
	}
}

.edfmat_input-group {
	margin-top: 2px;
	margin-bottom: 15px;
	padding: 10px;
	padding-top: 10px;
	padding-bottom: 2px;
	background-color: #f9f9f9;
}

//modal
.edfmat_modal {
	position: fixed;
	display: block;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;

	&.edfmat_modal--level2 {
		z-index: 2100;
	}
	&.edfmat_modal--level3 {
		z-index: 2200;
	}
	&.edfmat_modal--level4 {
		z-index: 2300;
	}

	&.hidden {
		display: none;
	}

	.edfmat_modal_background {
		background-color: rgba(0, 0, 0, 0.2);
		display: block;
		width: 100%;
		height: 100%;
	}

	.edfmat_modal_content {
		// background-color: #fff;
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.edfmat_modal_content_loading {
			background-color: rgba(255, 255, 255, 0.8);
			position: absolute;
			width: 100%;
			height: calc(100% - 100px);
			top: 0;
			left: 0;
			border-radius: 10px;
			z-index: 100;
			padding-top: 100px;
		}

		&.edfmat_modal_content--sm {
			width: 400px;
			// min-height: 250px;
		}

		&.edfmat_modal_content--md {
			width: 600px;
			// min-height: 350px;
		}

		&.edfmat_modal_content--lg {
			width: 800px;
			// min-height: 450px;
		}

		.close {
			position: absolute;
			top: 5px;
			right: 5px;
			width: 30px;
			height: 30px;
			font-size: 20px;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
		}
	}
}

/* Buttons
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Buttons
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.edfmat_button,
  //button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
	display: inline-block;
	height: 38px;
	min-width: 38px;
	//padding: 0 30px;
	color: #555;
	text-align: center;
	font-size: 11px;
	//font-weight: 600;
	line-height: 38px;
	letter-spacing: 0.1rem;
	//text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
	background-color: #fff;
	//border-radius: 4px;
	//border: 1px solid #bbb;
	box-shadow: $material-shadow1;

	border: none;
	cursor: pointer;
	box-sizing: border-box;

	padding-right: 15px;
	padding-left: 15px;
	// padding-top: 8px;
	// padding-bottom: 10px;
	//color: #fff;
	text-decoration: none;
	//margin-top: 12px;
	display: inline-block;
	//box-shadow: none;

	// &.edfmat_button--active{
	//   box-shadow: $material-shadow1;
	// }

	transition: box-shadow $transTime, transform $transTime;

	&:not(:last-of-type) {
		margin-right: 10px;
	}

	&.edfmat_button--sm {
		height: 28px;
		padding: 0 8px;
		font-size: 11px;
		line-height: 28px;
		min-width: 28px;

		&:not(:last-of-type) {
			margin-right: 4px;
		}
	}

	&.edfmat_button--abs {
		position: absolute;
	}

	&.edfmat_button--lg {
		height: 48px;
		padding: 0 20px;
		font-size: 14px;
		line-height: 48px;
		min-width: 48px;
		//font-weight: 600;
	}

	&:disabled,
	&.edfmat_button--disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
.edfmat_button:hover,
  //button:hover,
  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  .edfmat_button:focus,
  //button:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="button"]:focus {
	// color: #333;
	// border-color: #888;
	// outline: 0;
	//background-color: $primary-colour--light;
	//border-color: $primary-colour--light;
	box-shadow: $material-shadow2;
	transform: translateY(-2px);
}
.edfmat_button.edfmat_button--primary,
  //button.edfmat_button--primary,
  input[type="submit"].edfmat_button--primary,
  input[type="reset"].edfmat_button--primary,
  input[type="button"].edfmat_button--primary {
	color: #fff;
	background-color: $primary-colour;
	border-color: $primary-colour;
}
.edfmat_button.edfmat_button--primary:hover,
  //button.edfmat_button--primary:hover,
  input[type="submit"].edfmat_button--primary:hover,
  input[type="reset"].edfmat_button--primary:hover,
  input[type="button"].edfmat_button--primary:hover,
  .edfmat_button.edfmat_button--primary:focus,
  //button.edfmat_button--primary:focus,
  input[type="submit"].edfmat_button--primary:focus,
  input[type="reset"].edfmat_button--primary:focus,
  input[type="button"].edfmat_button--primary:focus {
	color: #fff;
	background-color: $primary-colour--light;
	//border-color: $primary-colour--light;
	//box-shadow: $material-shadow2;
}

.edfmat_button.edfmat_button--secondary,
  //button.edfmat_button--primary,
  input[type="submit"].edfmat_button--secondary,
  input[type="reset"].edfmat_button--secondary,
  input[type="button"].edfmat_button--secondary {
	color: #fff;
	background-color: $secondary-colour;
	border-color: $secondary-colour;
}
.edfmat_button.edfmat_button--secondary:hover,
  //button.edfmat_button--primary:hover,
  input[type="submit"].edfmat_button--secondary:hover,
  input[type="reset"].edfmat_button--secondary:hover,
  input[type="button"].edfmat_button--secondary:hover,
  .edfmat_button.edfmat_button--secondary:focus,
  //button.edfmat_button--primary:focus,
  input[type="submit"].edfmat_button--secondary:focus,
  input[type="reset"].edfmat_button--secondary:focus,
  input[type="button"].edfmat_button--secondary:focus {
	color: #fff;
	background-color: $secondary-colour--light;
	//border-color: $primary-colour--light;
}

.edfmat_paragraph-spacing {
	//mimic paragraph styling for blocks with have elements that can't be in paracraphs (such as divs)
	margin-bottom: 15px;
}

.edfm_textarea_big {
	min-height: 500px;
}

.edfm_popup_font_content {
	font-size: 2.75rem !important;
}
.edfm_popup_font_actions {
	font-size: 1.75rem !important;
}

.swal2-popup {
  font-size: 1.5rem !important;
}
