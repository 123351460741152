@font-face {
	font-family: FrutigerBold;
	src: url('./assets/fonts/FrutigerBold.woff2');
}

@font-face {
	font-family: FrutigerRoman;
	src: url('./assets/fonts/FrutigerRoman.woff2');
}

@import './assets/styling/react-datepicker.scss';

$primary-colour: #da3e00;
$primary-colour--light: #df4e15;

$secondary-colour: #0061bb;
$secondary-colour--light: #186dbd;

body {
	font-family: FrutigerRoman, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: #f9f9f9;
}

.additional-details {
	color: gray;
	font-size: 1rem;
}

//list broker page
.list-broker--addnew {
	margin-top: 15px;
}

.list-broker--loader {
	height: calc(100% - 60px);
	display: block;
	position: absolute;
	width: calc(100% - 20px);
	background-color: rgba(255, 255, 255, 0.8);
	top: 54px;
	z-index: 5;

	div {
		text-align: center;
	}
}

//ingest prices page
.ingest--brokerslist {
	max-height: 300px;
	min-height: 100px;
	overflow-y: auto;
	margin-bottom: 20px;
	background-color: #f9f9f9;
}

.ingest_status-strap {
	padding: 5px;
	padding-left: 10px;

	&.ingest_status-strap--LIVE {
		background-color: $primary-colour;
		color: #fff;
	}
	&.ingest_status-strap--VOID {
		background-color: #e1e1e1;
	}
	&.ingest_status-strap--DRAFT,
	&.ingest_status-strap--INGESTED {
		background-color: $secondary-colour;
		color: #fff;
	}
}

.commission-terms_strap {
	background-color: #e1e1e1;
	padding: 10px;

	.commission-terms_strap--label {
		background-color: $secondary-colour;
		color: #fff;

		padding: 5px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

.inline_linktext {
	cursor: pointer;
	color: $primary-colour;
	text-decoration: underline;
}

.floating-clear-holder {
	position: relative;
	display: block;

	.floating-clear {
		position: absolute;
		right: 2px;
		bottom: 21px;
	}
}

$material-shadow1: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
.report-details-panel {
	position: fixed;
	height: 100%;
	width: 500px;
	background-color: #fff;
	box-shadow: $material-shadow1;
	right: 0px;
	top: 0;
	opacity: 1;
	transition: right 125ms;
	padding: 20px;
	//padding-top: 85px;
	z-index: 1000;
	overflow-y: auto;

	&.report-details-panel--hidden {
		right: -500px;
		opacity: 0;
	}
}

#teller-dev-tools {
	position: fixed;
	bottom: 0;
	left: 0;
}
