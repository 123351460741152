.sales-list-item {
	display: flex;
	flex-direction: row;
	align-items: center;

	color: #838383;
	margin-top: 5px;

	> div {
		padding: 0 5px;
	}

	strong {
		color: black;
	}

	.col-long {
		flex: 3;
	}
	.col-medium {
		flex: 2;
	}
	.col-short {
		flex: 1;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		.edfmat_button {
			margin: 4px;
		}
	}
}
