.sales-list {
	display: flex;
	flex-direction: column;
	align-items: space-between;
	padding: 0;

	li {
		background-color: white;
	}
	li:nth-child(odd) {
		background-color: #f9f9f9;
	}
}
