$primary-colour: #da3e00;
$primary-colour--light: #df4e15;

$secondary-colour: #0061bb;
$secondary-colour--light: #186dbd;

// $light-gray: #f1f1f1;
// $dark-gray: #666;
// $blue: #0061bb;
// $red: #da3e00;
// $white: #ffffff;
// $lighter-gray: #f4f4f4;
