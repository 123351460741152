#statusFilterSection {
	width: 800px;
}

.status-badge {
	border-color: grey;
	border-style: solid;
	border-width: 2px;
	padding: 2px 4px;
	padding-right: 8px;
	height: 24px;
	display: inline-block;

	border-top-left-radius: 24px;
	border-bottom-left-radius: 24px;
	border-top-right-radius: 24px;
	border-bottom-right-radius: 24px;

	&.processing,
	&.sap-processing,
	&.live,
	&.finalised {
		color: #1cb900;
		border-color: #1cb900;
	}

	&.suspended {
		color: #e67c00;
		border-color: #e67c00;
	}

	&.not-found,
	&.error {
		color: red;
		border-color: red;
	}

	&.returned,
	&.contract-ended {
		color: gray;
		border-color: gray;
	}

	&.selected {
		background-color: rgb(209, 209, 209);
		border-color: black;
	}

	i {
		padding: 0 4px;
	}
}
