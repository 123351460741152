#workflow.container {
	min-width: 920px;

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex-row {
		display: flex;
		flex-direction: row;

		div {
			flex: 1;
			margin: 10px;
		}

		input.signDate {
			height: 38px;
			padding: 6px 10px;
			background-color: #fff;
			border: 1px solid #e1e1e1;
			border-radius: 4px;
			box-shadow: none;
			box-sizing: border-box;
			width: 100%;

			&:disabled {
				background-color: #e1e1e1;
				color: #b1b1b1;
			}
		}
	}

	.slightly-right {
		margin: 10px;
	}
}
